<template>
  <div class=" backdrop">
    <div class="container">
      <div class="flex-col">
        <div class="mb-3 header">
          <h1 class="text-center">Welcome to the AI Video Creator</h1>
          <p :style="{ fontSize: '20px' }" class="text-center">
            Create stunning video content for your campaign in minutes
          </p>
        </div>
        <div class="flex-card">
          <div :style="{ backgroundColor: 'yellow' }">
            <video>
              <source src="/ai-video.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div class="overlay">
              <div style="width: 100%;">
                <h3>AI Video Creator</h3>
                <button>Coming Soon</button>
              </div>
            </div>
          </div>
          <div class="py-3">
            <div class="video-editor">
              <img :src="require('~/assets/images/aiAutomation.png')" />
              <div :style="{ alignSelf: 'center' }">
                <h2 class="capitalize">Create cool campaign videos using our video composer</h2>
                <p :style="{ maxWidth: '90%', marginTop: '20px', fontSize: '18px' }">
                  Effortlessly design professional-quality campaign videos with our intuitive, feature-rich video
                  composer. Boost engagement, enhance your brand, and captivate your audience today!
                </p>
                <router-link
                  :style="{ textDecoration: 'none' }"
                  class="get-started d-inline-block bg-primary text-white mt-3 py-3 px-5"
                  :to="{ name: 'video-composer-wizard' }"
                >
                  Get Started Here
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VideoCreator',
  data: () => ({}),
};
</script>
<style lang="scss" scoped>
@import '~@/scss/variables';

$general-padding: 30px;
$card-border-radius: 15px;

.backdrop {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100vh;
  // background-color: $accent-b;
  // background-color: #b6dc0a29;
  padding-top: 100px;
  padding-bottom: 100px;
}
.container {
  max-width: 1500px;
  height: 100%;
  padding-left: $general-padding;
  padding-right: $general-padding;
  margin-left: auto;
  margin-right: auto;
}
.header {
  // background-color: rgba(127, 255, 212, 0.412);
  padding-top: 20px;
  border-radius: 10px;
}
.container > div {
  height: 100%;
}
.container p {
  font-weight: 400;
}
.container h1 {
  font-weight: 800 !important;
  margin-bottom: 0px;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-card {
  height: 100%;
  display: flex;
  gap: 30px;
  transition: all 1s;
}
// .flex-card > div:hover {
//   width: calc(100% * (3 / 4)) !important;
//   transition: all 1s;
// }

.flex-card > div:last-child {
  width: calc(100% * (3 / 4));
  padding-top: 35px;
  padding-bottom: 35px;
  & .video-editor {
    border-radius: $card-border-radius;
    background-color: white;
    padding: 40px;
  }
}
.flex-card > div:first-child {
  position: relative;
  transition: all 1s;
  width: calc(100% * (1 / 4));
  border-radius: $card-border-radius;
}

.flex-card > div:first-child video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: $card-border-radius;
}

.flex-card > div:first-child .overlay {
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  padding: 30px;
  display: flex;
  color: white;
  width: 100%;
  inset: 0;
}

.flex-card > div:first-child .overlay h3 {
  font-weight: 700;
  text-shadow: 0px 0px 1px black;
}

.flex-card > div:first-child .overlay button {
  background: linear-gradient(rgb(70, 188, 70), green);
  border-radius: 8px;
  padding: 20px 10px;
  font-weight: 600;
  color: white;
  letter-spacing: 4px;
  text-transform: uppercase;
  width: 100%;
  border: 0px;
}

.get-started {
  border-radius: 8px;
  font-weight: 600;
}

.video-editor {
  display: flex;
  gap: 40px;
  & img {
    object-fit: cover;
    border-radius: 15px;
    // height: 100%;
    // width: auto;
    max-height: 550px;
    width: calc(100% + 200px);
  }
  & h2 {
    font-weight: 600;
  }
}
.dev,
.dev * {
  outline: 1px solid rgba(200, 0, 0, 0.5);
}
</style>
